import { createSlice } from "@reduxjs/toolkit";
import { AnalyticsState } from "../../interfaces/analytics";

const initialState: AnalyticsState = {
  facebookPixelId: '',
  googleTagSendTo: '',
  googleTagId: '',
  googleTagInitialized: false,
  googleAnalyticsId: '',
  googleAnalyticsInitialized: false,
  fbPixelInitialized: false
};

export const AnalyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setAnalyticsParameters: (state, action) => {
      state.facebookPixelId = action.payload.facebookPixelId;
      state.googleTagId = action.payload.googleTagId;
      state.googleTagSendTo = action.payload.googleTagSendTo;
      state.googleAnalyticsId = action.payload.googleAnalyticsId;
    },
    setGoogleAnalyticsInitalized: (state, action) => {
      state.googleAnalyticsInitialized = action.payload;
    },
    setGoogleTagInitialized: (state, action) => {
      state.googleTagInitialized = action.payload;
    },
    setFbPixelInitialized: (state, action) => {
      state.fbPixelInitialized = action.payload;
    }
  },
});

export const { setAnalyticsParameters, setGoogleAnalyticsInitalized, setGoogleTagInitialized, setFbPixelInitialized } = AnalyticsSlice.actions;

export default AnalyticsSlice.reducer;
