import React from "react";
import { minuteDurationToText } from "../../helpers/utils";
import {
  BookingAppointment,
  BookingAppointmentType,
} from "../../interfaces/booking";
import "./Item.css";
import { useTranslation } from "react-i18next";
interface PropTypes {
  appointment: BookingAppointmentType;
  index: number,
  onSelect: ({
    id,
    duration,
  }: {
    id: BookingAppointment;
    duration: number;
  }) => void;
}

const AppointmentItem: React.FC<PropTypes> = ({ appointment, onSelect, index }) => {
  const { t } = useTranslation();
  const appointmentDuration = React.useMemo(
    () => minuteDurationToText(appointment.duration),
    [appointment]
  );

  const handleClick = React.useCallback(() => {
    onSelect({ id: appointment.id, duration: appointment.duration });
  }, [appointment, onSelect]);

  return (
    <div className={`appointment-item ${index % 2 === 1 ? "reverse" : ""} shadow-sm`}
      onClick={handleClick}
    >
      <img className="appointment-item__img" src={appointment.thumbnail} alt={appointment.thumbnail} />
      <div className="appointment-item__content">
        <div>
          <div className="text-md font-regular my-2">{t(appointment.title)}</div>
          <h6 className="text-sm font-bold">{appointmentDuration}</h6>

        </div>
      </div>
    </div>
  );
};

export default AppointmentItem;
