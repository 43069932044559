import React from "react";
import { useTranslation } from "react-i18next";
import WizardModal from "../components/Wizard/Modal";
import { viewPages } from "../constants/view";
import Booking from "../pages/Booking";
import Success from "../pages/Success";
import { useAppSelector } from "../store";

const ModalLayout = () => {
  const [shown, setShown] = React.useState<boolean>(true);
  const { t } = useTranslation();
  const page = useAppSelector((state) => state.view.page);

  const closeModal = React.useCallback(() => {
    setShown(false);
    window.parent.window.postMessage({ type: "eligo-modal-closed" }, "*");
  }, []);

  return (
    <React.Fragment>
      <WizardModal
        isShown={shown}
        headerText={t("BOOK_YOUR_APPOINTMENT")}
        onClose={closeModal}
      >{
          page === viewPages.SUCCESS ?
            <Success /> :
            <Booking />
        }
      </WizardModal>
    </React.Fragment>
  );
};

export default ModalLayout;
