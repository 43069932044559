import Color from "color";
import React from "react";
import useGoogleAnalytics from "./hooks/useAnalytics";
import ModalLayout from "./layouts/Modal";
import PageLayout from "./layouts/Page";
import WidgetLayout from "./layouts/Widget";
import { useAppDispatch, useAppSelector } from "./store";
import { setAnalyticsParameters } from "./store/features/analytics";
import { setViewParameters } from "./store/features/view";

declare var window: Partial<
  Window &
  typeof globalThis & {
    location: any;
    fbq: (...args: any[]) => any;
  }
>;

interface WidgetOptions {
  type: string;
  site?: string;
  stylistId?: string;
  primaryColor?: string;
  textContrast?: string;
  appointmentTypes?: string[];
  firstSlotDiff?: number;
  facebookPixelId?: string;
  googleTagId?: string;
  googleTagSendTo?: string;
  googleAnalyticsId?: string;
  tenantId?: string;
  toggled?: string;
  toggledExpiredDate?: string;
};

const getWidgetOptions = (): Promise<any> => {
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  const params: any = {};
  searchParams.forEach((value: any, key: string) => {
    params[key] = value;
  });

  return Promise.resolve(params);
};

const setColor = ({ color, variable }: { color?: string, variable: string }) => {
  try {
    if (!color) return;

    let shades = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
    let colorShades: any = {};
    for (let i = 0; i <= 4; i++) {
      let colorLighter = Color(color).hsl();
      let colorDarker = Color(color).hsl();
      colorLighter.color[2] = colorLighter.color[2] + (11 * i);
      colorDarker.color[2] = colorDarker.color[2] - (11 * i);
      colorShades[`${shades[5 - i]}`] = colorLighter.hex();
      colorShades[`${shades[i + 5]}`] = colorDarker.hex();
    }
    colorShades["500"] = Color(color).hex();
    document.documentElement.style.setProperty(`--${variable}-50`, colorShades["50"]);
    document.documentElement.style.setProperty(`--${variable}-100`, colorShades["100"]);
    document.documentElement.style.setProperty(`--${variable}-200`, colorShades["200"]);
    document.documentElement.style.setProperty(`--${variable}-300`, colorShades["300"]);
    document.documentElement.style.setProperty(`--${variable}-400`, colorShades["400"]);
    document.documentElement.style.setProperty(`--${variable}-500`, colorShades["500"]);
    document.documentElement.style.setProperty(`--${variable}-600`, colorShades["600"]);
    document.documentElement.style.setProperty(`--${variable}-700`, colorShades["700"]);

  } catch (e) {
    console.error(e);
  }
}

const App = () => {
  const dispatch = useAppDispatch();
  const type = useAppSelector((state) => state.view.type);
  useGoogleAnalytics();

  const initializeWidget = React.useCallback(async () => {
    try {
      const params: WidgetOptions = await getWidgetOptions();

      const { primaryColor, textContrast } = params;
      setColor({
        color: primaryColor,
        variable: "eligo-primary"
      });
      setColor({
        color: textContrast,
        variable: "eligo-contrast"
      });
      dispatch(setViewParameters(params));
      dispatch(setAnalyticsParameters(params));
    } catch (error) {
      console.error(error);

    }
  }, [dispatch]);

  React.useEffect(() => {
    initializeWidget();
  }, [initializeWidget])
  if (type === "modal") return <ModalLayout />;
  else if (type === "default") return <WidgetLayout />;
  else return <PageLayout />;
};

export default App;
