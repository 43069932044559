import React from "react";
import TimeOption from "../TimeOption";
import { DayCycle, TimeSlot } from "../../interfaces/time";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const getDayCycleText = (dayCycle: DayCycle): string => {
  return t(`DAY_TIME.${dayCycle.toUpperCase()}`);
};

interface PropTypes {
  selectedTime: string;
  dayCycle: DayCycle;
  slots: TimeSlot[];
}

const TimeSlotList: React.FC<PropTypes> = ({
  dayCycle,
  selectedTime,
  slots,
}) => {
  const { t } = useTranslation();
  const dayCycleText = React.useMemo<string>(
    () => getDayCycleText(dayCycle),
    [dayCycle]
  );

  return (
    <React.Fragment>
      <h3 className="timeslot-name">{dayCycleText}</h3>
      {slots.length > 0 ? (
        <div className="timeslot-container">
          {slots.map((timeSlot: TimeSlot, index: number) => (
            <TimeOption
              key={`${index}-${dayCycle}`}
              time={timeSlot.startTime}
              selected={timeSlot.startTime === selectedTime}
              disabled={timeSlot.disabled}
            />
          ))}
        </div>
      ) : (
        <div className="timeslot-empty">{t("NO_SLOT_AVAILABLE")}</div>
      )}
    </React.Fragment>
  );
};

export default TimeSlotList;
