import React from "react";
import { useTranslation } from "react-i18next";

const PageTitle: React.FC = () => {
  const { t } = useTranslation();
  return (
    <h3 className="mb-4 mt-1 w-full font-semibold" style={{ fontSize: 16 }}>
      {t("BOOK_ELIGO")}
    </h3>
  );
};

export default PageTitle;
