import axios from 'axios';
import { constructRequestHeaders, SERVER_URL } from "../helpers/request";

export default class LiveMeetingService {
    baseUrl: string = '';

    constructor(url?: string) {
        this.baseUrl = url || `${SERVER_URL}/v1/bookings`;
    }

    async createActivity(payload: any): Promise<any> {
        try {
            const response = await axios.post(`${this.baseUrl}/live/join`, payload, {
                headers: constructRequestHeaders()
            })
            return await Promise.resolve(response.data.data);
        } catch (error: any) {
            return await Promise.reject(error?.response?.data?.error || error);
        }
    }
}