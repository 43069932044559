import axios, { AxiosResponse } from "axios";
import {v4 as uuidv4} from "uuid";
import { constructRequestHeaders, SERVER_URL } from "../helpers/request";
import { MqttConfigOptions } from "../interfaces/mqtt";

export default class MqttService {
    baseUrl: string = '';
    constructor(url?: string) {
      this.baseUrl = url || `${SERVER_URL}/v1`;
    }
  
    async getAvailability(tenantId: string): Promise<any> {
      try {
        const payload = {
          widgetId: uuidv4()
        }
        const response: AxiosResponse = await axios.post(`${this.baseUrl}/bookings/live/request`, payload, {
          headers: {
            ...constructRequestHeaders(),
            "x-tenant-id": tenantId
          }
        });
        return await Promise.resolve({widgetId: payload.widgetId,data: response.data.data});
      } catch (error: any) {
        return await Promise.reject(error?.response?.data?.error || error);
      }
    }

    async registerMqtt(): Promise<MqttConfigOptions> {
      try {
        const response: AxiosResponse<{ data: MqttConfigOptions, meta: {} }> = await axios.get(`${this.baseUrl}/queue/register/mqtt`, { headers: constructRequestHeaders() });
        return await Promise.resolve(response.data.data);
      } catch (error: any) {
        return await Promise.reject(error?.response?.data?.error || error);
      }
    }
  }