import { createSlice } from "@reduxjs/toolkit";
import { BookingState } from "../../interfaces/booking";

const initialState: BookingState = {
  step: "appointment_type",
  appointment: {
    id: undefined,
    type: undefined,
    duration: 0,
  },
  date: undefined,
  time: undefined,
  user: undefined,
};

export const BookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    initWizard: (state, _action) => {
      state.step = initialState.step;
      state.appointment.id = initialState.appointment.id;
      state.appointment.duration = initialState.appointment.duration;
      state.date = initialState.date;
      state.time = initialState.time;
      state.user = initialState.user;
    },
    resetBookingDateTime: (state, _action) => {
      state.date = initialState.date;
      state.time = initialState.time;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setAppointmentType: (state, action) => {
      state.appointment = { ...action.payload };
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setTime: (state, action) => {
      state.time = action.payload;
      state.step = "info";
    },
    setUserData: (state, action: any) => {
      state.user = action.payload;
    },
  },
});

export const {
  resetBookingDateTime,
  setStep,
  setAppointmentType,
  setTime,
  setDate,
  setUserData,
  initWizard,
} = BookingSlice.actions;

export default BookingSlice.reducer;
