import dayjs from "dayjs";
import { useDayzed } from "dayzed";
import React, { useEffect, useState } from "react";
import { Calendar } from "../../components/Calendar";
import { constructDayCycleTimeSlots } from "../../helpers/time";
import { BookingStep } from "../../interfaces/booking";
import { DatePickerOptions } from "../../interfaces/datepicker";
import { useAppDispatch, useAppSelector } from "../../store";
import { setDate } from "../../store/features/booking";

// TODO: fix to include today aswell
const Datepicker: React.FC<DatePickerOptions> = ({ selected, onDateSelected, minDate }) => {
  let dayzedData = useDayzed({ selected, onDateSelected, minDate });
  return <Calendar {...dayzedData} />;
};

interface PropTypes {
  onNextStep: (step: BookingStep) => void;
}

const WizardDateStep: React.FC<PropTypes> = ({ onNextStep }) => {
  const date = useAppSelector((state) => state.booking.date);
  const appointment = useAppSelector((state) => state.booking.appointment);
  const firstSlotDiff = useAppSelector((state) => state.view.firstSlotDiff);
  const [minDate, setMinDate] = useState(dayjs().subtract(1, "day").toDate());
  const dispatch = useAppDispatch();

  useEffect(() => {
    const today = dayjs().toDate();

    const slots = constructDayCycleTimeSlots(appointment.duration, firstSlotDiff, today);

    if (!slots.flat().map(({ slots }) => slots).flat().length) {
      setMinDate(dayjs().toDate());
    }
  }, [appointment, firstSlotDiff])

  const handleDateSelect = ({ date }: any) => {
    dispatch(setDate(date));
    onNextStep("time");
  }

  return (
    <div className="flex flex-col mt-2 justify-between">
      <Datepicker
        selected={date as any}
        minDate={minDate}
        onDateSelected={handleDateSelect}
      />
    </div>
  );
};
export default WizardDateStep;
