import dayjs from "dayjs";
import { t } from "i18next";
import React from "react";
import { FaCalendarDay, FaCube, FaRegClock, FaRegUser } from "react-icons/fa";
import { BookingAppointment } from "../../interfaces/booking";
import { useAppDispatch, useAppSelector } from "../../store";
import { setStep } from "../../store/features/booking";
import Button from "../Button";

const getAppointmentTypeText = (id?: BookingAppointment) => {
  switch (id) {
    case "online_appointment":
      return "ONLINE";
    case "home_appointment":
      return "HOME";
    case "showroom_appointment":
      return "SHOWROOM";
    default:
      return "APPOINTMENT";
  }
};

const WizardStepper: React.FC = () => {
  const selectedTime = useAppSelector((state) => state.booking.time);
  const selectedDate = useAppSelector((state) => state.booking.date);
  const appointment = useAppSelector((state) => state.booking.appointment);
  const selectedAppointmentType = appointment.id;
  const appointmentTypeText = getAppointmentTypeText(appointment.id);
  const shownTypes = useAppSelector((state) => state.view.shownTypes);
  const dispatch = useAppDispatch();


  // TODO: fix the translation crap 
  return (
    <div className="stepper-container">
      {shownTypes.length > 1 ? <Button
        disabled={shownTypes.length === 1}
        className="stepper-btn"
        onClick={() => dispatch(setStep("appointment_type"))}
      >
        <FaCube size={14} />
        <span>{t("APPOINTMENT_TYPE_SELECTION." + appointmentTypeText) as String}</span>
      </Button> : null}

      <Button
        className="stepper-btn"
        disabled={selectedAppointmentType === undefined}
        onClick={() => dispatch(setStep("date"))}
      >
        <FaCalendarDay size={14} />
        <span>
          {selectedDate ? dayjs(selectedDate).format("DD/MM/YY") : t("DATE") as String}
        </span>
      </Button>
      <Button
        className="stepper-btn"
        disabled={selectedDate === undefined}
        onClick={() => dispatch(setStep("time"))}
      >
        <FaRegClock size={14} />
        <span>
          {selectedTime ? selectedTime : t("TIME") as string}
        </span>
      </Button>
      <Button
        className="stepper-btn"
        disabled={selectedTime === undefined}
        onClick={() => dispatch(setStep("info"))}
      >
        <FaRegUser size={14} />
        <span>{t("INFORMATION") as string}</span>
      </Button>
    </div>
  );
};
export default WizardStepper;
