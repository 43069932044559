import LiveMeetingService from "./LiveMeetingService";
import MeetingService from "./MeetingService";
import MqttService from "./MqttService";

const api = {
  MeetingService: new MeetingService(),
  MqttService: new MqttService(),
  LiveMeetingService: new LiveMeetingService()
};

export default api;
