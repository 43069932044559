import { BookingAppointmentType } from "../interfaces/booking";
export const bookingAppointmentTypes: BookingAppointmentType[] = [
  {
    id: "online_appointment",
    title: "APPOINTMENT_TYPE.ONLINE",
    type: "online",
    duration: 30,
    thumbnail:
      "https://static.wixstatic.com/media/7b9eea_d7168244aa6d406e9f98e196323177f3~mv2.jpg/v1/fill/w_4912,h_4912,fp_0.50_0.50,q_90/7b9eea_d7168244aa6d406e9f98e196323177f3~mv2.webp",
    actionText: "Book now",
  },
  {
    id: "home_appointment",
    type: "home",
    title: "APPOINTMENT_TYPE.HOME",
    duration: 60,
    thumbnail:
      "https://static.wixstatic.com/media/be775a_a2e9e006a57643baa3f521ccb736c3bb~mv2.jpg/v1/fill/w_4480,h_4480,fp_0.50_0.50,q_90/be775a_a2e9e006a57643baa3f521ccb736c3bb~mv2.webp",
    actionText: "Request to book",
  },
  {
    id: "showroom_appointment",
    title: "APPOINTMENT_TYPE.SHOWROOM",
    type: "showroom",
    duration: 60,
    thumbnail:
      "https://static.wixstatic.com/media/7b9eea_5233774421e44ce2a782b192e0cba28a~mv2.png/v1/fill/w_633,h_633,fp_0.50_0.50,q_90/7b9eea_5233774421e44ce2a782b192e0cba28a~mv2.webp",
    actionText: "Book now",
  },
];
