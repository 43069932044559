import React from "react";
import { useAppDispatch, useAppSelector } from "../store"
import mqtt, { MqttClient } from "precompiled-mqtt";
import { v4 as uuidv4 } from "uuid";
import { setError } from "../store/features/mqtt";
import api from "../api";

export const useMqtt = (gaEvent?: (options: any) => void) => {
    const { topic, broker, username, password, protocol, clientId, mqttInitialized } = useAppSelector(state => state.mqtt);
    const [client, setClient] = React.useState<MqttClient>();
    const dispatch = useAppDispatch();
    const initRef = React.useRef<boolean>(false);
    const removeClient = React.useCallback(() => {
        client?.unsubscribe(topic || "");
        client?.removeAllListeners();
        client?.end();
        dispatch(setError(true));
        setClient(undefined);
    }, [client, dispatch, topic])

    const handleMessage = React.useCallback(async (mqttTopic: string, message: any) => {
        if (!message || mqttTopic !== topic) return;
        const decodedMessage = JSON.parse(new TextDecoder("utf-8").decode(message));
        switch (decodedMessage.type) {
            case "widget/availability/accepted":
                gaEvent && gaEvent({
                    category: "starting_instant_meeting",
                    action: "live_start",
                    label: decodedMessage.payload.meeting_url
                });
                const meetingId = decodedMessage.payload.meeting_url.split("/").at(-1)
                api.LiveMeetingService.createActivity({
                    meetingId,
                    status: "redirect"
                })
                window.parent.window.postMessage({
                    type: "eligo-open-meeting",
                    url: decodedMessage.payload.meeting_url
                }, "*");
                break;
            case "widget/availability/unavailable":
                removeClient();
                break;
            default: break;
        }
    }, [removeClient, gaEvent, topic])

    React.useEffect(() => {
        if (!initRef.current && topic && mqttInitialized) {
            const mqttClient = mqtt.connect(`${protocol}://${broker}`, {
                username,
                password,
                protocol,
                host: broker,
                hostname: broker,
                clientId: `${clientId}-${uuidv4()}`
            })
            mqttClient.subscribe(topic);
            setClient(mqttClient);
            initRef.current = true;
        }
    }, [initRef, topic, mqttInitialized, broker, username, password, protocol, clientId])

    React.useEffect(() => {
        if (client) {
            client.on("connect", () => {
                console.log("Connected to Mqtt");
            });
            client.on("message", handleMessage)
            client.on("disconnect", () => {
                console.log("Mqtt disconnected");
                removeClient();
            });
        }
    }, [client, handleMessage, removeClient])

    return client;
}