import { DayCycleRanges } from "../interfaces/time";

export const dayCycleRanges: DayCycleRanges = {
  morning: {
    startTime: { hours: 9, minutes: 0 },
    endTime: { hours: 12, minutes: 0 },
  },
  afternoon: {
    startTime: { hours: 12, minutes: 0 },
    endTime: { hours: 17, minutes: 0 },
  },
  evening: {
    startTime: { hours: 17, minutes: 0 },
    endTime: { hours: 20, minutes: 0 },
  },
};
