import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { CalendarOptions } from '../interfaces/calendar';
import Button from './Button';

export const Calendar: React.FC<CalendarOptions> = ({ calendars, getBackProps, getForwardProps, getDateProps, }) => {
  const { t } = useTranslation();
  const monthNamesShort = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
  const weekdayNamesShort = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

  if (calendars.length) {
    return (
      <div className="calendar" >
        {calendars.map((calendar: any) => (
          <div key={`${calendar.month}${calendar.year}`} className="p-3 pt-0">
            <div className="month-selection">
              <button style={{ visibility: calendar.month === dayjs().month() ? "hidden" : "visible" }} {...getBackProps({ calendars })} type="button" className="ml-auto">
                <BiChevronLeft size={20} />
              </button>
              <h3>
                {t(`MONTH_ABR.${monthNamesShort[calendar.month]}`)} {calendar.year}
              </h3>
              <button {...getForwardProps({ calendars })} type="button" className="mr-auto">
                <BiChevronRight size={20} />
              </button>
            </div>
            <div className="calendar-container">
              {weekdayNamesShort.map(weekday => (
                <div className="calendar-weekday" key={`${calendar.month}${calendar.year}${weekday}`}>
                  {t(`DAY_ABR.${weekday}`)}
                </div>
              ))}
              {calendar.weeks.map((week: any, weekIndex: any) => {
                return week.map((dateObj: any, index: any) => {
                  let key = `${calendar.month}${calendar.year
                    }${weekIndex}${index}`;
                  if (!dateObj) {
                    return (
                      <div key={key} /> // NOTE: Empty boxes when we dont want to show days of other months
                    );
                  }
                  let { date, selected, selectable, today } = dateObj;
                  return (
                    // TODO: also find out how to do a nested fillable bg to indicate how much of the day's hour-timeslots are taken
                    // TODO: should do a selectable condition here in order to display selectable/ non-selectable elements (div for nonselectable)
                    <Button
                      className={classNames(
                        "calendar-day",
                        {
                          "calendar-day-selected": selected,
                          today
                        })}
                      key={key}
                      {...getDateProps({ dateObj })}
                    >
                      {selectable ? date.getDate() : null}
                    </Button>
                  );
                })
              }
              )}
            </div>

          </div>
        ))}
      </div>
    );
  }
  return null;
}