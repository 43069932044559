import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import eligo from "../../assets/images/eligo-logo.svg";
import Button from "../../components/Button";
import useFbPixel from "../../hooks/useFbPixel";
import useGoogleTag from "../../hooks/useGoogleTag";
import { useAppSelector } from "../../store";

const Success: React.FC = () => {
  const { t } = useTranslation();
  const { user, date, time } = useAppSelector((state) => state.booking);
  const { facebookPixelId, googleTagId, googleTagSendTo } = useAppSelector((state) => state.analytics);
  const [dateTime, setDatetime] = useState("");
  useFbPixel(facebookPixelId);
  useGoogleTag(googleTagId, googleTagSendTo);
  const constructDate = (date: string, hour: number, minute: number) => {
    const dayjsDate = dayjs(date).hour(hour).minute(minute);
    return dayjs(dayjsDate);
  };

  useEffect(() => {
    if (date && time) {
      const [hour, minute] = time.split(":");
      let fullDate = constructDate(date, parseInt(hour), parseInt(minute));
      setDatetime(fullDate.format("DD/MM/YYYY HH:mm"));
    }
  }, [date, time]);

  // const closeWidget = () => {
  //   dispatch(setPage(viewPages.HOME));
  //   dispatch(initWizard(undefined));
  //   if (shownTypes.length === 1) {
  //     dispatch(setStep("date"));
  //   }
  //   dispatch(setToggled(false));
  //   if (type === "modal") {
  //     !onClose || onClose();
  //   }
  // };

  return (
    <div className="success-page">
      <div className="eligo-live">
        <div className="bg-white">
          <img src={eligo} alt="" />
        </div>
        <div className="pulse bg-white"></div>
        <h4 className="mt-1">LIVE</h4>
      </div>
      <div className="flex flex-col justify-between h-[85%]">
        <Button disabled full className=" border-4">
          {t("SUCCESS.THANKS_MSG")}
        </Button>

        <div className="final-info">
          <div className="text-left">
            <label>{t("INFO.FULLNAME")}</label>
            <p className="mb-1">{user?.name} {user?.surname}</p>
            <label>{t("INFO.PHONE")}</label>
            <p>{user?.phone}</p>
            <label>Email</label>
            <p className="mb-1">{user?.email}</p>
          </div>
          <div className="text-left">
            <label>{t("DATE")}</label>
            <p className="mb-1">{dateTime}</p>
            <label>{t("INFO.ADDITIONAL_NOTES")}</label>
            <p>{user?.notes}</p>
          </div>
        </div>
        <p className="my-2" dangerouslySetInnerHTML={{ __html: t("SUCCESS.CONFIRMATION_FEEDBACK") }} />
      </div>
    </div >

  );
};
export default Success;
