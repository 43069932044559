import { createSlice, } from "@reduxjs/toolkit";
import { ViewState } from "../../interfaces/view";

const initialState: ViewState = {
  page: "home",
  toggled: false,
  type: "modal",
  view: "lg",
  stylistId: undefined,
  tenantId: "",
  site: undefined,
  shownTypes: ["online", "home", "showroom"],
  loading: false,
  firstSlotDiff: 2,
  toggledExpiredDate: undefined
};

export const ViewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setToggled: (state, action) => {
      state.toggled = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
    },
    setToggledExpiredDate: (state, action) => {
      state.toggledExpiredDate = action.payload;
    },
    setViewParameters: (state, action) => {
      state.type = action.payload.type;
      state.stylistId = action.payload.stylistId;
      state.site = action.payload.site;
      state.shownTypes = action.payload.appointmentTypes ? action.payload.appointmentTypes.split(",") : initialState.shownTypes;
      state.firstSlotDiff = Number(action.payload.firstSlotDiff) || 0; // CHECK: changed undefined to 0 with no understanding of consequence
      state.tenantId = action.payload.tenantId;
      state.toggledExpiredDate = action.payload.toggledExpiredDate;
    }
  },
});

export const { setPage, setToggled, setLoading, setView, setViewParameters, setToggledExpiredDate } = ViewSlice.actions;

export default ViewSlice.reducer;
