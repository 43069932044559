import { getI18n } from "react-i18next";

export const minuteDurationToText = (duration: number): string => {
  let hours = 0,
    minutes = 0;

  if (duration >= 60) {
    hours = Math.floor(duration / 60);
    minutes = duration % 60;
  } else minutes = duration;

  return (
    [
      { value: hours, unit: getI18n().t("HOUR_ABR") },
      { value: minutes, unit: getI18n().t("MIN_ABR") },
    ]
      .filter(({ value }) => value > 0)
      .map(({ value, unit }) => [value, unit].join(" "))
      .join(", ") || "N/A"
  );
};
