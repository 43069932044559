
import React from "react";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { setGoogleTagInitialized } from "../store/features/analytics";

const useGoogleTag = (googleTagId: string, googleTagSendTo: string) => {
    const googleTagInitialized = useAppSelector((state: RootState) => state.analytics.googleTagInitialized)
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        if (!googleTagInitialized && googleTagId && googleTagSendTo) {
            const initScript = document.createElement("script");
            initScript.id = "gtag-init";
            initScript.async = true;
            initScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleTagId}`;
            const script = document.createElement("script");
            script.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${googleTagId}');
            `

            script.id = "gtag-config"
            const conversionScript = document.createElement('script');
            conversionScript.innerHTML = `
                gtag(
                    'event',
                    'conversion',
                    {
                        'send_to': '${googleTagSendTo}'
                    }
                ); 
            `
            conversionScript.id = "gtag-event";
            document.body.appendChild(initScript);
            document.body.appendChild(script);
            document.body.appendChild(conversionScript);
            dispatch(setGoogleTagInitialized(true));
        }
    }, [googleTagInitialized,dispatch, googleTagSendTo, googleTagId]);
};

export default useGoogleTag;