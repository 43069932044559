import React from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { setFbPixelInitialized } from "../store/features/analytics";

const useFbPixel = (facebookPixelId: string) => {
  const fbPixelInitialized = useAppSelector(state => state.analytics.fbPixelInitialized);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (!fbPixelInitialized && facebookPixelId) {
      let script = document.createElement("script");
      script.id = "fb-script";
      script.innerHTML = `
          !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
            n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
            document,'script','https://connect.facebook.net/en_US/fbevents.js');
            // Insert Your Facebook Pixel ID below. 
            fbq('init', ${facebookPixelId});
            fbq('track', 'SubmitApplication');
          `;
      let noscript = document.createElement("noscript");
      noscript.id = "fb-noscript";
      let img = document.createElement("img");
      img.height = 1;
      img.width = 1;
      img.setAttribute("style", "display: none;");
      img.src = `https://www.facebook.com/tr?id=${facebookPixelId}&amp;ev=PageView&amp;noscript=1`;
      noscript.appendChild(img);

      document.body.appendChild(script);
      document.body.appendChild(noscript);
      dispatch(setFbPixelInitialized(true));
    }
  }, [facebookPixelId, fbPixelInitialized, dispatch]);
};

export default useFbPixel;