import classNames from 'classnames';
import React from 'react';

interface PropTypes {
  type?: 'button' | 'submit';
  full?: boolean;
}

const Button: React.FC<PropTypes & React.HTMLProps<HTMLButtonElement>> = ({ children, type = 'button', onClick, disabled, full, style, className }) => {
  return <button type={type} onClick={onClick} disabled={disabled} style={style} className={classNames("button", className, {
    "button--disabled": disabled,
    "button--full": full
  })}>
    {children}
  </button>
}
export default Button;