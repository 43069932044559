import { AxiosRequestHeaders } from "axios";
import { v4 as uuidv4 } from "uuid";
import { api, applicationId } from "../constants/appSettings";

export const SERVER_URL: string = `${api.protocol}://${api.host}${
  !!api.port ? `:${api.port}` : ""
}`;

export const constructRequestHeaders = ( payload?: any ): AxiosRequestHeaders => {
  const headers: AxiosRequestHeaders = {};

  headers["x-application-id"] = applicationId;
  headers["x-request-id"] = uuidv4();
  headers["x-tenant-id"] = payload?.tenantId || "";
  return headers;
};
