import React from "react";
import { useTranslation } from "react-i18next";
import { FaCube } from "react-icons/fa";
import { bookingAppointmentTypes } from "../../constants/booking";
import { BookingStep } from "../../interfaces/booking";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  resetBookingDateTime,
  setAppointmentType,
} from "../../store/features/booking";
import AppointmentItem from "../Appointment/Item";
import DescriptionBlock from "../DescriptionBlock";

interface PropTypes {
  onNextStep: (step: BookingStep) => void;
}

const WizardAppointmentTypeStep: React.FC<PropTypes> = ({ onNextStep }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const appointmentDuration = useAppSelector((state) => state.booking.appointment.duration);
  const shownTypes = useAppSelector((state) => state.view.shownTypes);

  const handleSelect = React.useCallback(
    (appointmentType: any) => {
      if (appointmentDuration !== appointmentType.duration)
        dispatch(resetBookingDateTime({}));

      dispatch(setAppointmentType(appointmentType));
      onNextStep("date");
    },
    [appointmentDuration, onNextStep, dispatch]
  );

  return (
    <div className="flex flex-col justify-between">
      <DescriptionBlock
        text={t("BOOK_YOUR_APPOINTMENT")}
        icon={<FaCube className="text-eligo-primary-500" />}
      />
      <div
        className="py-2 pb-4 -ml-2 text-black"
      >


        {bookingAppointmentTypes.map((bat, index) => (
          shownTypes.includes(bat.type) && <AppointmentItem key={bat.id} appointment={bat} index={index} onSelect={handleSelect} />
        ))}
      </div>
    </div>
  );
};

export default WizardAppointmentTypeStep;
