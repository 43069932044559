import classNames from "classnames";
import React from "react";
import product1 from "../assets/images/Product1.png";
import product2 from "../assets/images/Product2.png";
import product3 from "../assets/images/Product3.png";
import product4 from "../assets/images/Product4.png";
import product5 from "../assets/images/Product5.png";


const ImageCarousel: React.FC = () => {
	const [index, setIndex] = React.useState(0);
	React.useLayoutEffect(() => {
		const interval = setInterval(() => {
			setIndex((index) => index === 4 ? 0 : index + 1);
		}, 3000)
		return () => clearInterval(interval);
	}, [index])
	return <div className="carousel">
		<div className="pulse absolute top-4 right-4 bg-eligo-primary-500 z-10"></div>
		<img src={product1} className={classNames({ active: index === 0 })} alt="" />
		<img src={product2} className={classNames({ active: index === 1 })} alt="" />
		<img src={product3} className={classNames({ active: index === 2 })} alt="" />
		<img src={product4} className={classNames({ active: index === 3 })} alt="" />
		<img src={product5} className={classNames({ active: index === 4 })} alt="" />
	</div>
}

export default ImageCarousel;