import React from "react";
import { constructDayCycleTimeSlots } from "../../helpers/time";
import { useAppSelector } from "../../store";
import TimeSlotList from "../Time/SlotList";

const WizardTimeStep: React.FC = () => {
  const appointmentDuration = useAppSelector((state) => state.booking.appointment.duration);
  const selectedTime = useAppSelector((state) => state.booking.time);
  const date = useAppSelector((state) => state.booking.date);
  const firstSlotDiff = useAppSelector((state) => state.view.firstSlotDiff);

  const dayCycleTimeSlots = React.useMemo(() => {
    return constructDayCycleTimeSlots(appointmentDuration, firstSlotDiff, date as any)
  }, [appointmentDuration, firstSlotDiff, date]);

  return (
    <div className="timeslot-list" >
      {dayCycleTimeSlots.map((slot) => (
        <TimeSlotList
          key={slot.id}
          dayCycle={slot.id}
          slots={slot.slots}
          selectedTime={selectedTime as any}
        />
      ))}
    </div>
  );
};
export default WizardTimeStep;
