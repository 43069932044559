/* eslint-disable no-underscore-dangle */

declare var window: Partial<
  Window &
    typeof globalThis & {
      _env_: any;
    }
>;

const {
  REACT_APP_API_PROTOCOL,
  REACT_APP_API_HOST,
  REACT_APP_API_PORT,
  REACT_APP_ID,
  REACT_APP_NAME,
  REACT_APP_VERSION,
} = window._env_;


// API domain
const api = {
  protocol: REACT_APP_API_PROTOCOL,
  host: REACT_APP_API_HOST,
  port: REACT_APP_API_PORT,
};

// const api = {
//   protocol: "https", // REACT_APP_API_PROTOCOL,
//   host: "api-stg.eligomilano.com", // REACT_APP_API_HOST,
//   port: "443", // REACT_APP_API_PORT,
// };
const applicationId = (REACT_APP_ID) ? REACT_APP_ID : "330f2fbd-9cb6-421d-b8b1-605abde6a81e";

const applicationCopyright = [
  "©",
  new Date().getFullYear(),
  ["-", REACT_APP_NAME || "PLATFORM"].join(""),
  ["V", REACT_APP_VERSION].join(""),
].join(" ");

export { api, applicationId, applicationCopyright };
