import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import it from "./locales/it.json";
import detector from "i18next-browser-languagedetector";
const resources = {
  en: { translation: en },
  it: { translation: it },
};

const defaultLang: string = 'it';
const DETECTION_OPTIONS = {
  order: ['querystring', 'localStorage', 'navigator'],
  caches: ['localStorage'],
  lookupQuerystring: 'lng',
  lookupLocalStorage: undefined,
};
i18n
  .use(initReactI18next)
  .use(detector)
  .init({
    detection: DETECTION_OPTIONS,
    resources,
    supportedLngs: ['en', 'it'],
    fallbackLng: defaultLang,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
