import React from 'react';

interface PropTypes {
  icon: any;
  text: string;
}

const DescriptionBlock: React.FC<PropTypes> = ({ icon, text }) => {
  return (
    <div className="flex flex-row items-center justify-center my-2">
      {icon}
      <span className="ml-1">{text}</span>
    </div>
  )
}
export default DescriptionBlock;