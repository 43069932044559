import { viewPages } from "../constants/view";
import Booking from "../pages/Booking";
import Success from "../pages/Success";
import { useAppSelector } from "../store";

const PageLayout = () => {
	const page = useAppSelector((state) => state.view.page);

	return (
		<div className="page">
			{page === viewPages.SUCCESS ?
				<Success /> :
				<Booking />}
		</div>
	);
};

export default PageLayout;
