import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MqttConfigOptions, MqttState } from "../../interfaces/mqtt";

const initialState: MqttState = {
  broker: "",
  clientId: "",
  enabled: undefined,
  username: undefined,
  password: undefined,
  protocol: 'wss',
  mqttInitialized: false,
  error: false,
  widgetId: undefined,
  topic: undefined,
  loading: false
}

export const mqttSlice = createSlice({
  name: 'mqtt',
  initialState,
  reducers: {
    initializeMqtt(state, { payload }: PayloadAction<MqttConfigOptions & { tenantId: string }>) {
      state.mqttInitialized = true;
      state.broker = payload.broker;
      state.clientId = payload.clientId;
      state.enabled = payload.enabled;
      state.password = payload.password;
      state.username = payload.username;
      state.protocol = payload.protocol;
      state.topic = `eligo/${payload.tenantId}/widget/${state.widgetId}`;
      state.loading = true;
    },
    setWidgetId(state, { payload }) {
      state.widgetId = payload;
    },
    setError(state, { payload }) {
      state.error = !!payload;
      state.loading = false;
    },
    reset(state) {
      state.mqttInitialized = initialState.mqttInitialized;
      state.broker = initialState.broker;
      state.clientId = initialState.clientId;
      state.enabled = initialState.enabled;
      state.password = initialState.password;
      state.username = initialState.username;
      state.protocol = initialState.protocol;
      state.loading = initialState.loading;
      state.error = initialState.error;
    }
  },
  extraReducers: () => {
  }
})

export const { initializeMqtt, setWidgetId, setError, reset } = mqttSlice.actions;
export default mqttSlice.reducer;